<template>
  <div class="EditSection mb-4">
    <div class="flex justify-between mb-4">
      <div class="flex items-center">
        <BackButton :href="`/admin/gamification/badges`" :cssClass="'mr-4'" />
        <h2 class="text-2xl font-bold">{{ badge.name }}</h2>
      </div>
      <div class="ToggleContainer field flex items-center field--checkbox gap-2">
        <Transition name="fade">
          <button @click.prevent="updateEnabled(params.active)" v-if="isEnabledChanged && params.active !== initialEnabled" :class="['Btn', {'Btn--Save': params.active, 'Btn--Delete': !params.active}]" type="submit">
            {{ params.active ? t('actions.save_to_activate') : t('actions.save_to_deactivate') }}
          </button>
        </Transition>
        <label for="cn-enabled" :class="['text-lg font-bold', {'text-danger': !params.active, 'text-success': params.active}]">
          {{ t('pages.gamification.badges.title') }} {{ !params.active ? t('global.not') : '' }} {{ t('global.active.s') }}
        </label>
        <label for="cn-enabled" class="Switch">
          <input v-model="params.active" id="cn-enabled" type="checkbox">
          <span class="Slider round"></span>
        </label>
      </div>
    </div>

    <form class="Form--Boxed mb-4">
      <div class="EditSection__Infos">
        <div class="grid grid-cols-3 gap-3">
          <div class="field">
            <label for="name" class="inline-block text-lg">{{ t('pages.prizes.name') }}</label>
            <input v-model="params.name" id="name" class="mb-4" type="text" placeholder="Nome" aria-label="Nome" />
          </div>
          <div class="field">
            <label for="points" class="inline-block text-lg">{{ t('pages.prizes.points') }}</label>
            <input v-model="params.points" id="points" class="mb-4" type="number" placeholder="Punti" aria-label="Punti" />
          </div>
          <div class="field col-span-3">
            <label for="description" class="inline-block text-lg">{{ t('pages.prizes.description') }}</label>
            <textarea v-model="params.description" id="description" class="mb-4" placeholder="Descrizione" aria-label="Descrizione"></textarea>
          </div>
          <!-- Start Date
          <div class="field col-span-3 flex items-center">
            <input type="checkbox" v-model="showStartDate" id="startDateCheckbox" />
            <label for="startDateCheckbox" class="inline-block text-lg pt-2 ml-3">
              {{ t('pages.gamification.badges.select_start_date') }}
            </label>
            <button :content="t('pages.gamification.badges.start_date_expl')" v-tippy class="Btn--Tooltip ml-2">
              <svg height="18" width="18" class="fill-current"> 
                <use xlink:href="#icon-information-outline"></use>
              </svg>
            </button>
          </div>
          <div class="field col-span-3" v-if="showStartDate">
            <label for="start_date" class="inline-block text-lg">{{ t('actions.select_date') }}</label>
            <input v-model="params.start_date" id="start_date" type="date" class="mb-4" aria-label="Start Date" />
          </div>
           -->
        </div>
        <div class="flex justify-end">
          <button @click.prevent="updateBadge(params)" class="Btn Btn--Save ml-2" type="button">
            {{ t('pages.gamification.badges.update_badge') }}
          </button>
        </div>
      </div>
    </form>

    <form class="Form--Boxed mb-4">
      <div class="EditSection__Infos">
        <div class="grid grid-cols-3 gap-3">
          <div class="field">
            <label for="event" class="inline-block text-lg">{{ t('global.event') }}</label>
            <select v-model="params.badge_event_id" id="event" class="mb-4" required aria-label="Event">
              <option v-for="event in events" :key="event.id" :value="event.id">{{ event.description }}</option>
            </select>
          </div>
          <div class="field" v-if="showLessons">
            <label for="lessons" class="inline-block text-lg">{{ t('pages.gamification.badges.lecture_number') }}</label>
            <input v-model="params.target_number" id="lessons" class="mb-4" type="number" placeholder="Numero di lezioni" aria-label="Numero di lezioni" />
          </div>
          <div v-if="showCourses" class="field col-span-3">
            <div class="mb-4 flex items-center justify-between">
              <h4>{{ t('pages.gamification.badges.select_courses') }}</h4>
              <div>
                <button type="button" @click="selectAll" class="Btn Btn--Success">{{ t('pages.custom_notifications.select_all_courses') }}</button>
                <button type="button" @click="removeAll" class="Btn Btn--Delete">{{ t('actions.remove_all_courses') }}</button>
              </div>
            </div>
            <div class="flex flex-1 mb-4">
              <input v-model="search" type="text" :placeholder="t('pages.courses.search_undefined')" class="flex-1 mr-2" @keyup.enter="getCoursesFromSearch" />
              <button type="button" @click="getCoursesFromSearch" class="Btn Btn--Save">{{ t('actions.search') }}</button>
            </div>
            <div v-if="loading" class="LoadingBox mt-4 text-center">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div v-else-if="error" class="Box Box--Clear">
              <p class="text-danger">{{ error }}</p>
            </div>
            <div v-else>
              <div v-if="courses.length > 0">
                <ul class="List--Lined List--Lined--Thin">
                  <li v-for="course in pageOfItems" :key="course.id" class="flex items-center justify-between">
                    <span>{{ course.name }}</span>
                    <button type="button" class="flex items-center" @click="toggleCourseSelection(course)">
                      <span class="mr-2" :class="course.selected ? 'text-success' : 'text-warning'">
                        {{ course.selected ? 'Selezionato' : 'Non selezionato' }}
                      </span>
                      <span :class="course.selected ? 'Btn Btn--CheckStyle Btn--CheckStyle--Checked' : 'Btn Btn--CheckStyle Btn--CheckStyle--UnChecked'">
                        <svg height="16" width="16" class="fill-current">
                          <use xlink:href="#icon-checkmark"></use>
                        </svg>
                      </span>
                    </button>
                  </li>
                </ul>
                <div class="text-center mt-2">
                  <jw-pagination :items="courses" @changePage="onChangePage" :labels="customLabels" class="PaginationList"></jw-pagination>
                </div>
              </div>
              <div v-else class="mt-4 text-center">
                <p>{{ t('pages.courses.no_courses') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button @click.prevent="saveBadgeWithCriteria" class="Btn Btn--Save" type="button">
            {{ t('pages.gamification.badges.save_criteria') }}
          </button>
        </div>
      </div>
    </form>

    <div class="Form--Boxed mb-4">
      <div class="field">
        <label for="cn-lang" class="text-lg font-bold mb-2">{{ t('global.language') }}</label>
        <select v-model="selectedLang" id="cn-lang" class="Select mb-4" aria-label="Lang">
          <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{ lang.code }}</option>
        </select>
      </div>
      <div class="EditSection__Infos" v-for="translation in translations" :key="translation.language">
        <div v-if="selectedLang === translation.language">
          <div class="field">
            <label for="b-name" class="text-lg">{{ t('pages.prizes.name') }}</label>
            <input v-model="translation.name" id="b-name" class="mb-4" type="text" placeholder="" aria-label="Nome" />
          </div>
          <div class="field">
            <label for="b-desc" class="text-lg">{{ t('pages.prizes.description') }}</label>
            <input v-model="translation.description" id="b-desc" class="mb-4" type="text" placeholder="" aria-label="Description" />
          </div>
          <div class="flex justify-end">
            <button @click.prevent="updateTranslation(translation)" class="Btn Btn--Save" type="button">
              {{ t('actions.save') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="Form--Boxed mb-4">
      <uploadMedia
        id="badge-image"
        :label="t('global.image')"
        :preview="badge.url"
        accept="image/*"
        :post-action="getPostUploadUrl()"
      >
        <template v-slot:preview>
          <img v-if="badge.url" :src="badge.url" width="200px" class="mb-4" />
        </template>
      </uploadMedia>
    </div>
  </div>
</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'
import uploadMedia from '../../commons/uploadMedia'
import { global as EventBus } from '../../../js/utils/eventBus'

export default {
  name: 'AdminBadgeEdit',
  props: {
    badge: Object,
    events: Array,
    languages: Array
  },
  components: {
    BackButton,
    uploadMedia
  },
  data() {
    return {
      params: {
        name: this.badge.name || '',
        points: this.badge.points || 0,
        description: this.badge.description || '',
        badge_event_id: this.badge.badge_event_id || null,
        target_number: 0,
        selected_courses_ids: [],
        active: this.badge.active || false,
        // start_date: this.badge.start_date || '',
      },
      translations: this.badge.translations || [],
      selectedLang: 'it',
      showCourses: false,
      showLessons: false,
      search: '',
      page: 1,
      totalPages: 1,
      loading: false,
      courses: [],
      selectedCourses: [],
      addedCourses: [],
      removedCourses: [],
      customLabels: {
        previous: '«',
        next: '»'
      },
      error: '',
      pagy: {},
      pageOfItems: [],
      criteriaId: null,
      isEnabledChanged: false,
      initialEnabled: this.badge.active,
      // showStartDate: false,
    }
  },
  computed: {
  },
  watch: {
    'params.badge_event_id': function (newVal, oldVal) {
    this.setShowFields(newVal); 
    this.getCourses();
  }
  },
  mounted() {
    console.log('Badge object on page load:', this.badge);
    
    this.initializeBadgeCriteria()
    this.setShowFields(this.params.badge_event_id);
      if (this.showCourses) {
        this.getCourses();
      }
    // if (this.badge.start_date) {
    //   this.showStartDate = true;
    //   this.params.start_date = this.formatDateForInput(this.badge.start_date);
    // }
  },
  methods: {

    setShowFields(badge_event_id) {
      console.log('Evento selezionato:', badge_event_id);
      this.courses = [];
      this.params.selected_courses_ids = [];
      
      this.showCourses = badge_event_id === 1;
      this.showLessons = badge_event_id === 2;
      if (this.showCourses) {
        this.getCourses();
      }
    },
      updateEnabled(enabled) {
      this.isEnabledChanged = true;
      this.params.active = enabled;
    },
    async initializeBadgeCriteria() {
    console.log('initializeBadgeCriteria called');
    try {
      console.log('Badge ID:', this.badge.id);
      const criteriaArray = await api.BadgeAssignmentCriteria.getByBadgeId(this.badge.id);
      console.log('Criteria received:', criteriaArray);
      if (criteriaArray && criteriaArray.length > 0) {
        const criteria = criteriaArray[0];
        this.criteriaId = criteria.id;
        this.params.target_number = criteria.target_number || 0;
        console.log('Target number:', this.params.target_number);
        this.params.selected_courses_ids = criteria.selected_courses_ids || [];
        console.log('Selected courses IDs:', this.params.selected_courses_ids);
        this.selectedCourses = criteria.selected_courses || [];
        console.log('Selected courses:', this.selectedCourses);
        this.params.badge_event_id = criteria.badge_event_id || this.params.badge_event_id;
        console.log('Badge event ID:', this.params.badge_event_id);
        this.setShowFields(this.params.badge_event_id);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Error loading badge criteria: Not Found (404)');
        this.$toastr.e(this.t('errors.badge_criteria_not_found'));
      } else {
        console.error('Error loading badge criteria:', error);
        this.$toastr.e(this.t('errors.unexpected_error'));
      }
    }
  },
  formatDateForInput(date) {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  },
    setShowFields(badge_event_id) {
      this.showCourses = badge_event_id === 1
      this.showLessons = badge_event_id === 2
    },
    async saveBadgeWithCriteria(event) {
    const target = event.currentTarget;
    EventBus.emit('loader:local:start', { elem: target });

    try {
      const criteriaParams = {
        badge_id: this.badge.id,
        badge_event_id: this.params.badge_event_id,
        target_number: this.params.target_number,
        selected_courses_ids: this.params.selected_courses_ids,
      };

      let response;
      if (this.criteriaId) {
        response = await api.BadgeAssignmentCriteria.update(this.criteriaId, criteriaParams);
      } else {
        response = await api.BadgeAssignmentCriteria.create(criteriaParams);
      }

      console.log('Server response:', response);

      if (response.success) {
        this.$toastr.s(this.t('actions.saved'));
      } else {
        const errorMsg = response.errors ? response.errors.join(', ') : 'Unknown error';
        throw new Error(errorMsg);
      }
    } catch (err) {
      console.error('Error saving badge criteria:', err.message);
      this.$toastr.e(this.t('actions.error'));
    } finally {
      EventBus.emit('loader:local:end', { elem: target });
    }
  },
    async updateBadge(params) {
      this.isEnabledChanged = false;
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target });
      try {
        const updatedParams = {
        ...params,
        active: this.params.active,
        // start_date: this.showStartDate ? this.params.start_date : null,
      };
      const res = await api.Badge.updateBadge(this.badge.id, updatedParams);
      this.badge = res.data;
      this.params = { ...updatedParams };
      this.$toastr.s(this.t('actions.saved'));
    } catch (err) {
        this.$toastr.e(this.t('actions.error'));
      } finally {
        EventBus.emit('loader:local:end', { elem: target });
        location.reload();
      }
    },
    async updateTranslation(translation) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target });
      try {
        await api.Badge.updateTranslation(this.badge.id, {
          translation_id: translation.id,
          name: translation.name,
          description: translation.description,
        });
        this.$toastr.s(this.t('actions.saved'));
      } catch (err) {
        this.$toastr.e(this.t('actions.error'));
      } finally {
        EventBus.emit('loader:local:end', { elem: target });
      }
    },
    getPostUploadUrl() {
      return `/admin/gamification/badges/${this.badge.id}/upload`;
    },
    async getCourses() {
      this.loading = true;
      try {
        const response = await api.Badge.getCourses({
          search: this.search,
          id: this.badge.id,
        });
        console.log('API response:', response);
        this.courses = response.map(course => ({
          ...course,
          selected: this.params.selected_courses_ids.includes(course.id),
        }));
        this.pageOfItems = this.courses.slice(0, 10);
        this.totalPages = Math.ceil(this.courses.length / 10);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    toggleCourseSelection(course) {
      if (course.selected) {
        this.removeCourse(course);
      } else {
        this.addCourse(course);
      }
    },
    addCourse(course) {
      course.selected = true;
      this.selectedCourses.push(course);
      this.params.selected_courses_ids.push(course.id);
      if (this.removedCourses.some(item => item.id === course.id)) {
        this.removedCourses = this.removedCourses.filter(obj => obj.id !== course.id);
      } else {
        this.addedCourses.push(course);
      }
    },
    removeCourse(course) {
      course.selected = false;
      this.selectedCourses = this.selectedCourses.filter(c => c.id !== course.id);
      this.params.selected_courses_ids = this.params.selected_courses_ids.filter(id => id !== course.id);
      if (this.addedCourses.some(item => item.id === course.id)) {
        this.addedCourses = this.addedCourses.filter(obj => obj.id !== course.id);
      } else {
        this.removedCourses.push(course);
      }
    },
    selectAll() {
      this.courses.forEach(course => {
        if (!course.selected) {
          this.addCourse(course);
        }
      });
    },
    removeAll() {
      this.courses.forEach(course => {
        if (course.selected) {
          this.removeCourse(course);
        }
      });
    },
    getCoursesFromSearch() {
      this.page = 1;
      this.getCourses();
    },
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
  },
}
</script>

<style lang="scss">
#cn-lang {
  text-transform: uppercase;
  max-width: 200px;
}
.ToggleContainer {
  height: 40px;
}
</style>